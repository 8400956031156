/* const items = [
  {
    title: "Software Engineer- R&D",
    content: "4-10 yrs",
    link: "software-engineer",
  },
  {
    title: "Sr Angular Developer",
    content: "4-10 yrs",
    link: "sr-angular-developer",
  },
  {
    title: "Graphic UI/UX Designer",
    content: "4-10 yrs",
    link: "graphic-designer",
  },
  {
    title: "Sr Laravel Developer",
    content: "4-10 yrs",
    link: "sr-laravel-developer",
  },
  {
    title: "Sr Python Developer",
    content: "4-10 yrs",
    link: "sr-python-developer",
  },
  {
    title: "Sr QA Engineer",
    content: "4-10 yrs",
    link: "sr-qa-engineer",
  },
  {
    title: "Sr DevOps",
    content: "4-10 yrs",
    link: "sr-devops",
  },
]; */

export const growthStrategist = [
  {
    title: "Responsibilities: ",
    items: [
      {
        subtitle: "Strategic Growth Planning: ",
        para: [
          "Develop and execute innovative growth strategies aligned with the company's vision and business goals.",
          "Collaborate with cross-functional teams to identify market opportunities and areas for strategic expansion.",
        ],
      },
      {
        subtitle: "Product Innovation: ",
        para: [
          "Drive innovation in product development, ensuring our offerings remain at the forefront of industry trends.",
          "Collaborate with the tech team to introduce new features and enhancements that differentiate us in the market.",
        ],
      },
      {
        subtitle: "User Acquisition and Retention: ",
        para: [
          "Devise and implement strategies to acquire and retain users/customers.",
          "Analyse user behaviour and feedback to optimise user journeys and enhance overall customer satisfaction.",
        ],
      },
      {
        subtitle: "Data-Driven Decision Making: ",
        para: [
          "Leverage data analytics and market insights to inform decision-making processes.",
          "Conduct A/B testing and other experiments to optimise user engagement and conversion rates.",
        ],
      },
      {
        subtitle: "Partnerships and Alliances: ",
        para: [
          "Identify and foster strategic partnerships to amplify our reach and market presence.",
          "Collaborate with external partners to drive joint initiatives that contribute to mutual growth.",
        ],
      },
      {
        subtitle: "Brand Building: ",
        para: [
          "Contribute to the development and execution of branding and positioning strategies.",
          "Work closely with marketing teams to ensure consistent and compelling brand messaging.",
        ],
      },
    ],
  },
  {
    title: "Requirements: ",
    items: [
      {
        subtitle: "",
        para: ["Bachelor’s degree in Marketing, Business, or a related field."],
      },
      {
        subtitle: "",
        para: [
          "Proven experience as a Growth Hacker, Growth Strategist, or in a similar role.",
        ],
      },
      {
        subtitle: "",
        para: [
          "Demonstrated success in driving user acquisition and revenue growth for technology products/services.",
        ],
      },
      {
        subtitle: "",
        para: ["Strong analytical skills and a data-driven mindset."],
      },
      {
        subtitle: "",
        para: [
          "Creative thinking with a passion for innovation and staying ahead of industry trends.",
        ],
      },
      {
        subtitle: "",
        para: ["Excellent communication and interpersonal skills."],
      },
    ],
  },
];

export const officeManager = [
  {
    title: "Responsibilities: ",
    items: [
      {
        subtitle: "Office Management: ",
        para: [
          "Oversee the day-to-day operations of our WeWork office, ensuring a conducive and efficient work environment.",
          "Collaborate with vendors and manage relationships to ensure smooth office logistics.",
        ],
      },
      {
        subtitle: "Talent Acquisition and Onboarding: ",
        para: [
          "Work closely with leadership to identify, attract, and onboard top talent aligned with our vision.",
          "Manage the recruitment process for immediate team requirements.",
        ],
      },
      {
        subtitle: "Cultural Leadership: ",
        para: [
          "Champion and nurture our organisational culture, fostering an environment of innovation, collaboration, and continuous improvement.",
          "Develop and implement initiatives that enhance employee engagement and satisfaction.",
        ],
      },
      {
        subtitle: "Expansion Planning: ",
        para: [
          "Contribute to the strategic planning of team expansion, considering office space requirements and future growth projections.",
          "Collaborate with leadership to plan the transition from WeWork to a dedicated independent space.",
        ],
      },
      {
        subtitle: "Holiday Planning and Roster Management: ",
        para: [
          "Coordinate and finalise annual holiday schedules, ensuring coverage during extended breaks.",
          "Manage a roster system for employees to work during office closures, maintaining essential functions.",
        ],
      },
      {
        subtitle: "Strategic Vision Alignment: ",
        para: [
          "Align day-to-day operations with the long-term vision of the company, contributing to the realisation of our ambitious goals.",
        ],
      },
    ],
  },
  {
    title: "Requirements: ",
    items: [
      {
        subtitle: "",
        para: [
          "Proven experience in office management and culture building, preferably in a fast-paced tech environment.",
        ],
      },
      {
        subtitle: "",
        para: [
          "Strong organisational and leadership skills with a passion for nurturing a positive work culture.",
        ],
      },
      {
        subtitle: "",
        para: ["Experience in talent acquisition and onboarding processes."],
      },
      {
        subtitle: "",
        para: [
          "Strategic thinker with the ability to align day-to-day activities with long-term vision.",
        ],
      },
      {
        subtitle: "",
        para: ["Excellent communication and interpersonal skills."],
      },
    ],
  },
];

export const softwareEngineer = [
  {
    title: "Responsibilities: ",
    items: [
      "Collaborate with cross-functional teams to design, develop, and maintain web applications using PHP, Laravel, and JavaScript.",
      "Create and optimise database schemas using MySQL to ensure data integrity and performance.",
      "Participate in the entire application development lifecycle, focusing on coding and debugging.",
      "Work with front-end developers to integrate user-facing elements using efficient and scalable server-side logic.",
      "Write well-documented and clean code to meet project requirements and industry standards.",
      "Contribute to the planning and implementation of software development projects.",
      "Stay up to date with industry trends and advancements, bringing innovative ideas and solutions to the team.",
      "Troubleshoot, test, and maintain the core product software and databases.",
    ],
  },
  {
    title: "Requirements: ",
    items: [
      "Strong understanding of PHP web development and experience with Laravel framework.",
      "Knowledge of database design and optimization, with hands-on experience in MySQL.",
      "Familiarity with version control systems (e.g., Git) and agile development methodologies.",
      "Ability to work independently and collaboratively in a team environment.",
      "Strong problem-solving and analytical skills.",
      "Excellent communication skills with the ability to convey technical concepts to non-technical stakeholders.",
    ],
  },
];

export const benefits = [
  "Competitive salary",
  "Opportunity to be part of a high-growth tech company",
  "Health and wellness programs",
  "Collaborative and innovative work culture",
];
