import { Link } from "gatsby";
import React from "react";
import JoinCS from "../components/JoinCS";
import PageWrapper from "../components/PageWrapper";
import AboutCareer from "../sections/careers/office-manager/AboutCareer";
import Details from "../sections/careers/office-manager/Details";
import CtaSec from "../sections/homepage/cta";

const OfficeManager = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
              <Link to="/contact">
                <button className="btn btn-fox-violet header-btn-2 font-size-3 rounded-5">
                  Let's talk
                </button>
              </Link>
            </>
          ),
          footerStyle: "style3",
        }}
      >
        <div className="container pt-24 pb-14 pb-md-18 pb-lg-24">
          <div className="col-lg-9 col-md-9 col-xs-12">
            <Link to="/careers" className="font-size-4 pb-4 text-blue m-3">
              ← BACK TO CAREERS PAGE
            </Link>
            <h1 className="font-size-10 pb-4">Office and Culture Manager</h1>
            <div className="d-flex">
              <p className="font-size-6 d-flex align-items-center m-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  className="mr-2"
                  viewBox="0 0 512 512"
                >
                  <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                </svg>
                4-10 yrs
              </p>
              <p className="font-size-6 d-flex align-items-center m-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  className="ml-9 mr-2"
                  viewBox="0 0 384 512"
                >
                  <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                </svg>
                Bangalore, India
              </p>
            </div>
            <Link
              className="mb-1 mt-6"
              href="mailto:hello@cloudstackai.com"
              role="button"
              aria-expanded="false"
            >
              <button className="btn btn-fox-violet header-btn-2 font-size-3 rounded-5">
                Apply now
              </button>
            </Link>
            <AboutCareer />
            <div className="mt-10 pt-8">
              <Details />
            </div>
          </div>
          <JoinCS title="Office and Culture Manager" />
          <h4
            className="font-size-9 text-center p-4 mt-5"
            data-aos="zoom-in"
            data-aos-duration={300}
            data-aos-delay={300}
          >
            Join Cloudstack, where the future is crafted.
          </h4>
        </div>
        <CtaSec />
      </PageWrapper>
    </>
  );
};
export default OfficeManager;
