import React from "react";

const JoinCS = ({ title }) => {
  return (
    <div
      className="border-radius-sm border rounded shadow-2 p-10 m-5 mt-7 mb-7"
      data-aos="fade-up"
      data-aos-duration={600}
    >
      <p className="font-size-7 text-center">
        If you are a smart, passionate individual with a heart for
        organisational culture, ready to embark on an exciting journey with us,
        we'd love to hear from you! Please send your resume and a cover letter
        to <b>career@cloudstackai.com</b> with the subject line "Application for{" "}
        {title}”
      </p>
    </div>
  );
};

export default JoinCS;
